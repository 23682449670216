<template>
    <OModal ref="downloadDocsModal" name="downloadDocsModal" @show="onShow">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!props.downloadFromDetails">{{$t('Download Documents')}}</h5>
                    <h5 class="modal-title" v-if="props.downloadFromDetails">{{$t('Download Document')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="!props.downloadFromDetails">
                        <span style="font-size: small; font-weight: 500;">
                            {{$t('Rows selected with full permission to download: ')}}
                            <span v-if="selectedIds == null || selectedIdsWithAccess == null" class="spinner-border spinner-border-sm"></span>
                            <span v-else>{{ selectedIdsWithAccess }}/{{ selectedIds.length }}</span>
                        </span>

                        <ul v-if="selectedIds != null && selectedIdsWithAccess != null" style="font-size: smaller; margin-top: 5px;">
                            <li v-if="selectedIds.length > selectedIdsWithAccess">
                                {{ selectedIdsWithAccess }} document(s) with access to download main file
                            </li>
                            <li v-if="selectedIdsEmptyFileRef > 0">
                                {{ selectedIdsEmptyFileRef }} document(s) with no main files available for download
                            </li>
                        </ul>
                    </div>
                    <div class="mt-3">
                        <span style="font-size:small;">{{$t('Please choose a file naming convention for your download:')}}</span>
                        <div style="font-size:small; color:red;" v-if="props.downloadFromDetails && selectedIdsWithAccess == 0">{{$t('Missing permission to download main file.')}}</div>
                    </div>
                    <select class="form-control" v-model="vSelectedNamingConvention" style="max-width:350px" >
                        <option value="filename">{{$t('Filename')}}</option>
                        <option v-for="row in dsSyntaxes.data" :value="'custom_' + row.ID">{{row.Title}}</option>
                    </select>
                    <div class="form-check mt-3">
                        <label class="form-check-label">
                        <input class="form-check-input include-attachments" type="checkbox" v-model="vIncludeAttachments">
                            {{$t('Include Attachments')}}
                        </label> 
                    </div> 
                    <div class="form-check">
                        <label class="form-check-label">
                        <input class="form-check-input include-rendition" type="checkbox" v-model="vIncludeRendition">
                            {{$t('Include PDF Rendition')}}
                        </label>
                    </div>
                    <div class="ms-5 form-check" id="include-rendition-opts" v-if="!props.isDFO && vIncludeRendition">
                        <label class="form-check-label">
                        <input class="form-check-input include-reviewfile" type="checkbox" v-model="vIncludeReviewFile">
                            {{$t('Review file')}}
                        </label>
                        <div class="form-check-inline ms-2" id="include-reviewfile-opts" v-if="vIncludeReviewFile">
                            <div class="form-check form-check-inline" :title="$t('If document has a finalized review file, download this instead of the PDF rendition')">
                                <input class="form-check-input" name="includeReviewfileOpts" type="radio" id="reviewfile-substitute" checked value="sbst" v-model="vReviewFileSubstituteInclude">
                                <label class="form-check-label" for="reviewfile-substitute">{{$t('Substitute')}}</label>
                            </div>
                            <div class="form-check form-check-inline" :title="$t('Include both the PDF rendition and the finalized review file (if there exists one) in the download')">
                                <input class="form-check-input" name="includeReviewfileOpts" type="radio" id="reviewfile-include" value="incl" v-model="vReviewFileSubstituteInclude">
                                <label class="form-check-label" for="reviewfile-include">{{$t('Include')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="downloadDocuments" :disabled="vWorking || selectedIds == null"><span v-if="vWorking" class="spinner-border spinner-border-sm me-1"></span>{{$t('Download')}}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules'
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { context } from 'o365-modules';
    import { alert } from 'o365-vue-services';

    const props = defineProps({
        dataObject: Object,
        isDFO: {
            type: Boolean, 
            default: false
        },
        downloadFromDetails: {
            type: Boolean,
            default: false
        }
    })

    const dsSyntaxesDef = {
        id: 'dsSyntaxes',
        viewName: "aviw_Arena_FileNameSyntaxesLookup",
        appId: props.dataObject.appId,
        maxRecords: 500,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number" },
                {name: "Title", type: "string", sortOrder: 1, sortDirection: "asc"}
            ]
    }

    const downloadDocsModal = ref(null), vSelectedNamingConvention = ref("filename"), vIncludeAttachments = ref(false), vIncludeRendition = ref(false), vIncludeReviewFile = ref(false), vReviewFileSubstituteInclude = ref("sbst"), vWorking = ref(false),
        selectedIds = ref(null), selectedIdsWithAccess = ref(null), selectedIdsEmptyFileRef = ref(null);

    const dsSyntaxes = getOrCreateDataObject(dsSyntaxesDef);

    const procDocsSelected = getOrCreateProcedure({
        id: 'procDocsSelected',
        procedureName: 'astp_Arena_DocumentsSelected'
    });
    const procReqsSelected = getOrCreateProcedure({
        id: 'procReqsSelected',
        procedureName: 'astp_Arena_ObjectsDocRequirementsSelected'
    });
    const procCheckSize = getOrCreateProcedure({
        id: 'procCheckSize',
        procedureName: 'astp_Arena_DocumentsDownloadSelectedCheckSize'
    });
    const procMarkAsViewed = getOrCreateProcedure({
        id: 'procMarkAsViewed',
        procedureName: 'astp_Arena_MarkDocumentAsViewed'
    });

    const procGetSelectedCount = new getOrCreateProcedure({ 
        id: "procGetSelectedCount", 
        procedureName: "astp_Arena_DocumentsDownloadSelectedCount" 
    });


    const onShow = async () => {
        selectedIds.value = null;
        if (props.downloadFromDetails){
            selectedIds.value = [[props.dataObject.current.ID]];
        } else {
            selectedIds.value = null;
            await props.dataObject.selectionControl.getSelectedRows({fields:[{name:"ID"}]}).then(rows => {
                selectedIds.value = rows.map(r => [r.ID]);
            });
        }
        
        if (selectedIds.value && selectedIds.value.length > 0) {
            const response = await procGetSelectedCount.execute({ Documents: selectedIds.value });
            selectedIdsWithAccess.value = response?.Table?.[0]?.WithAccessToDownload || 0;
            selectedIdsEmptyFileRef.value = response?.Table?.[0]?.EmptyFileRef || 0;
        }
        
        dsSyntaxes.recordSource.whereClause = "'"+ context.idPath+ "' LIKE IdPath"
        await dsSyntaxes.load();
    }

    // TODO: warning/confirm if obsolete/deleted
    const downloadDocuments = async() => {
        // as of 28.06.24
        // console.log(selectedIds.value.length) // 213 (expected)
        // console.log(props.dataObject.selectionControl.selectedRows.length) // 50
        // console.log(props.dataObject.data.filter(row => row.isSelected).length) // 50
        // debugger;
        if (!selectedIds.value.length){
            alert($t('No documents selected'),'warning');
            return
        }
        vWorking.value = true;

        if (!props.isDFO){
            await procDocsSelected.execute({Values:selectedIds.value});
        } else {
            await procReqsSelected.execute({Values:selectedIds.value});
        }

        //TODO: logUserAsViewed
        procCheckSize.execute({
            IncludeRendition:vIncludeRendition.value, IncludeAttachments:vIncludeAttachments.value, ReviewFileOption: (vIncludeReviewFile.value ? vReviewFileSubstituteInclude.value : ""), isDFO: props.isDFO
        }).then((res)=>{
            if (res.Table[0].Ok){
                window.open('/api/arena/downloadselectedfiles/false/' + props.isDFO + '/' + vIncludeAttachments.value + '/' + vIncludeRendition.value + '/' + vSelectedNamingConvention.value + (vIncludeReviewFile.value ? "/"+vReviewFileSubstituteInclude.value : ""));
                //console.log('/api/arena/downloadselectedfiles/false/false/' + vIncludeAttachments.value + '/' + vIncludeRendition.value + '/' + vSelectedNamingConvention.value + (vIncludeReviewFile.value ? "/"+vReviewFileSubstituteInclude.value : ""))
            } else {
                let vAlert = $t("The combined size of the files you tried to download") + " (" + res.Table[0].SumFileSizeMB + " ) " + $t("exceeds the maximum allowed size of")+" " + res.Table[0].MaxDownloadSizeMB + ". " + $t("Please try again with a smaller batch of documents") ;
                alert(vAlert,'warning');
            }

            vWorking.value = false;
        });
    }
</script>